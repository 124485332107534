var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ClaimTypeTile from '@backoffice/components/claim/ClaimTypeTile.vue';
import TimeLine from '@backoffice/components/claim/TimeLine.vue';
import { getStatus } from '@backoffice/claim/status';
import { EXIT_TYPES_TRAD_KEYS } from '@backoffice/claim/exit';
import { ExitTypes } from '@shared/types/api/claim';
import { UserRole } from '@shared/types/api/user';
import { uuidFromIri } from '@shared/utils/iri';
import { CONFIG } from '@shared/environment/environment';
import { INSURER } from '@shared/insurer';
const claim = namespace('claim');
const isOpenKey = 'claim-backoffice:sidebar-is-open';
const currentTabKey = 'claim-backoffice:sidebar-current-tab';
let SidePanel = class SidePanel extends Vue {
    constructor() {
        super(...arguments);
        this.isOpen = true;
        this.isSavingNote = false;
        this.currentNote = '';
        this.currentTab = 0;
        this.uuidFromIri = uuidFromIri;
    }
    get savedNote() {
        var _a;
        if (!this.info) {
            return '';
        }
        return (_a = this.info.claim.note) !== null && _a !== void 0 ? _a : '';
    }
    get noteHasChanged() {
        return this.currentNote.trim() !== this.savedNote.trim();
    }
    get insurer() {
        return INSURER;
    }
    get secondaryId() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.secondaryId) !== null && _b !== void 0 ? _b : '-';
    }
    get expertMissionIdForRen() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.expertMissionIdForRen) !== null && _b !== void 0 ? _b : '-';
    }
    get formattedMagicLink() {
        if (!this.magicLink)
            return '-';
        return this.magicLink;
    }
    isDefined(value) {
        return typeof value !== 'undefined' && value !== null;
    }
    mounted() {
        var _a, _b, _c;
        this.currentNote = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.note) !== null && _b !== void 0 ? _b : '';
        if (localStorage) {
            this.isOpen = localStorage.getItem(isOpenKey) === 'true';
            this.currentTab = Number((_c = localStorage.getItem(currentTabKey)) !== null && _c !== void 0 ? _c : 0);
        }
    }
    persistIsOpen(isOpen) {
        if (localStorage) {
            localStorage.setItem(isOpenKey, JSON.stringify(isOpen));
        }
    }
    persistCurrentTab(currentTab) {
        if (localStorage) {
            localStorage.setItem(currentTabKey, JSON.stringify(currentTab));
        }
    }
    async saveCurrentNote() {
        if (!this.noteHasChanged)
            return;
        this.isSavingNote = true;
        await this.saveNote(this.currentNote);
        this.isSavingNote = false;
    }
    revertNote() {
        if (!this.info) {
            return;
        }
        this.currentNote = this.info.claim.note || '';
    }
    get claim() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) !== null && _b !== void 0 ? _b : null;
    }
    get insured() {
        var _a, _b;
        return (_b = (_a = this.contract) === null || _a === void 0 ? void 0 : _a.contact) !== null && _b !== void 0 ? _b : null;
    }
    get insuredName() {
        if (!this.insured)
            return null;
        return this.insured.firstName + ' ' + this.insured.lastName;
    }
    get insuredPhone() {
        if (!this.insured)
            return null;
        return this.insured.mobilePhone || this.insured.landlinePhone || '-';
    }
    get claimStatus() {
        if (!this.claim)
            return null;
        return this.$tc(getStatus(this.$config.insurerConfig, this.claim.status).tradKey);
    }
    get claimExit() {
        if (!this.claim)
            return null;
        const { forcedExit, exit } = this.claim;
        return this.$tc(EXIT_TYPES_TRAD_KEYS[forcedExit || exit || ExitTypes.Unknown]);
    }
    get claimExitExplanation() {
        if (!this.claim)
            return null;
        const { exit, forcedExit, exitExplanation } = this.claim;
        if (!exit)
            return null;
        if (forcedExit)
            return 'Cette issue a été forcée par un gestionnaire';
        if (![ExitTypes.Indemnisation, ExitTypes.Unknown].includes(exit))
            return exitExplanation || null;
        return null;
    }
    get isAdmin() {
        return this.$hasAccessLevel(UserRole.ADMIN);
    }
    get primings() {
        var _a, _b;
        if (!this.claim)
            return ['-'];
        const primings = [...(_a = this.claim) === null || _a === void 0 ? void 0 : _a.primings, ...(_b = this.claim) === null || _b === void 0 ? void 0 : _b.generaliPrimings];
        return primings.length ? primings : ['-'];
    }
    get pdfUrl() {
        if (!this.claim)
            return '-';
        return `${CONFIG.apiUrl}/pdf/claim-report/${uuidFromIri(this.claim.id)}`;
    }
    get pdfRenUrl() {
        if (!this.claim)
            return '-';
        const locale = this.$i18n.localeProperties.iso;
        return `${CONFIG.apiUrl}/pdf/ren/${locale}/${uuidFromIri(this.claim.id)}`;
    }
    get pdfFilesUrl() {
        if (!this.claim)
            return '-';
        return `${CONFIG.apiUrl}/pdf/claim-files/${uuidFromIri(this.claim.id)}`;
    }
};
__decorate([
    claim.State('caseNumber')
], SidePanel.prototype, "caseNumber", void 0);
__decorate([
    claim.State('info')
], SidePanel.prototype, "info", void 0);
__decorate([
    claim.State('contract')
], SidePanel.prototype, "contract", void 0);
__decorate([
    claim.State('priming')
], SidePanel.prototype, "selectedPriming", void 0);
__decorate([
    claim.State('isInfoLoading')
], SidePanel.prototype, "isInfoLoading", void 0);
__decorate([
    claim.State('magicLink')
], SidePanel.prototype, "magicLink", void 0);
__decorate([
    claim.Getter('typeTrigram')
], SidePanel.prototype, "trigram", void 0);
__decorate([
    claim.Action('saveNote')
], SidePanel.prototype, "saveNote", void 0);
__decorate([
    claim.Action('fetchPriming')
], SidePanel.prototype, "fetchPriming", void 0);
__decorate([
    Watch('isOpen')
], SidePanel.prototype, "persistIsOpen", null);
__decorate([
    Watch('currentTab')
], SidePanel.prototype, "persistCurrentTab", null);
SidePanel = __decorate([
    Component({
        components: { ClaimTypeTile, TimeLine },
        filters: { toUuid: (str) => (str ? uuidFromIri(str) : '-') },
    })
], SidePanel);
export default SidePanel;
