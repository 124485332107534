export var ClaimCompensationSectionType;
(function (ClaimCompensationSectionType) {
    ClaimCompensationSectionType["REALESTATEDAMAGES"] = "realEstateDamages";
    ClaimCompensationSectionType["PERSONALPROPERTIESDAMAGES"] = "personalProperties";
    ClaimCompensationSectionType["TOTALS"] = "totals";
})(ClaimCompensationSectionType || (ClaimCompensationSectionType = {}));
export var ClaimCompensationColumnName;
(function (ClaimCompensationColumnName) {
    ClaimCompensationColumnName["Label"] = "label";
    ClaimCompensationColumnName["Quantity"] = "quantity";
    ClaimCompensationColumnName["ValuationReplacement"] = "valuation.replacement";
    ClaimCompensationColumnName["ValuationObsolescence"] = "valuation.obsolescence";
    ClaimCompensationColumnName["ValuationObsolescenceDeducted"] = "valuation.obsolescence-deducted";
    ClaimCompensationColumnName["CompensationRecoverable"] = "compensation.recoverable-obsolescence";
    ClaimCompensationColumnName["CompensationType"] = "compensation.type";
    ClaimCompensationColumnName["CompensationImmediate"] = "compensation.immediate";
    ClaimCompensationColumnName["CompensationDelayed"] = "compensation.delayed";
    ClaimCompensationColumnName["CompensationTotal"] = "compensation.total";
})(ClaimCompensationColumnName || (ClaimCompensationColumnName = {}));
export var ClaimCompensationCellName;
(function (ClaimCompensationCellName) {
    ClaimCompensationCellName["TotalsSectionCompensationOriginalImmediate"] = "totals-section.compensation.original.immediate";
    ClaimCompensationCellName["TotalsSectionDeductibleForcedImmediate"] = "totals-section.deductible.forced.immediate";
    ClaimCompensationCellName["TotalsSectionCompensationForcedImmediate"] = "totals-section.compensation.forced.immediate";
    ClaimCompensationCellName["TotalsSectionCompensationForcedDelayed"] = "totals-section.compensation.forced.delayed";
    ClaimCompensationCellName["TotalsSectionCompensationForcedTotal"] = "totals-section.compensation.forced.total";
})(ClaimCompensationCellName || (ClaimCompensationCellName = {}));
