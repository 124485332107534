var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AllowanceSubRow from './AllowanceSubRow.vue';
import ForcableAmountValue from 'backoffice/components/claim/compensation/ForcableAmountValue.vue';
import { ClaimCompensationCellName, } from '@backoffice/types/store';
import formatAmount from '@shared/utils/formatAmount';
import { computeCompensationDetails } from '@backoffice/compensation/compensationDetails';
const claim = namespace('claim');
let DamagesCompensationForcedRows = class DamagesCompensationForcedRows extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
        this.value = {};
    }
    get counting() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting) !== null && _b !== void 0 ? _b : null;
    }
    get isForcedAmountsSubRow() {
        var _a, _b, _c, _d;
        return this.type === 'footer' && ((_d = !!((_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.edit) === null || _c === void 0 ? void 0 : _c.rows)) !== null && _d !== void 0 ? _d : false);
    }
    mounted() {
        this.row.cells.forEach((cell) => {
            if (this.isForcedInputCell(cell)) {
                this.$set(this.value, cell.name, {
                    cellName: cell.name,
                    amount: cell.displayValue ? Number(cell.displayValue) : null,
                });
            }
        });
    }
    getValue(cellName) {
        if (!this.value[cellName]) {
            this.$set(this.value, cellName, { cellName, amount: null });
        }
        return this.value[cellName];
    }
    getRowClasses(row) {
        var _a;
        const cssClasses = [];
        if (row.type === 'intermediate-result') {
            cssClasses.push('has-text-weight-bold has-background-light');
        }
        if (row.type === 'default' || row.type === 'applied-minimum-charge') {
            cssClasses.push('damage-row__default');
        }
        if (row.type === 'post-footer') {
            cssClasses.push('damage-row__post-footer');
        }
        if (row.type === 'metadata' && !this.isEditingClaim) {
            cssClasses.push('damage-row__add-damage--hidden');
        }
        if (this.type === 'footer' && ((_a = row.data) === null || _a === void 0 ? void 0 : _a.isForcedDeductible)) {
            cssClasses.push('deductible-amount_row');
        }
        return cssClasses;
    }
    getCellClasses(cell) {
        const cssClasses = [];
        const cssType = 'damage-table-column__' + cell.data.columnName.split('.').pop();
        if (cell.type === 'label') {
            cssClasses.push('has-text-weight-normal');
        }
        cssClasses.push(cssType);
        if (this.row.type === 'footer') {
            cssClasses.push('has-text-weight-bold');
        }
        if (cell.type === 'amount') {
            cssClasses.push('has-text-right');
        }
        return cssClasses.join(' ');
    }
    isForcedInputCell(cell) {
        return (cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedImmediate ||
            cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedDelayed ||
            cell.name === ClaimCompensationCellName.TotalsSectionDeductibleForcedImmediate);
    }
    get compensationDetails() {
        if (!this.info)
            return null;
        return computeCompensationDetails(this.info, this.$tc.bind(this));
    }
};
__decorate([
    claim.State('initialClaim')
], DamagesCompensationForcedRows.prototype, "initialClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], DamagesCompensationForcedRows.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.State('draftPayload')
], DamagesCompensationForcedRows.prototype, "draftPayload", void 0);
__decorate([
    claim.State('info')
], DamagesCompensationForcedRows.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], DamagesCompensationForcedRows.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], DamagesCompensationForcedRows.prototype, "updateDraftPayload", void 0);
DamagesCompensationForcedRows = __decorate([
    Component({
        components: { ForcableAmountValue, AllowanceSubRow },
        props: {
            row: {
                type: Object,
                required: true,
            },
        },
        filters: {
            formatAmount,
        },
    })
], DamagesCompensationForcedRows);
export default DamagesCompensationForcedRows;
