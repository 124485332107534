var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AllowanceSubRow from './AllowanceSubRow.vue';
import ForcableAmountValue from 'backoffice/components/claim/compensation/ForcableAmountValue.vue';
import { ClaimCompensationCellName, ClaimCompensationColumnName, ClaimCompensationSectionType, } from '@backoffice/types/store';
import formatAmount from '@shared/utils/formatAmount';
import { COMPENSATION_MODE_CHOICES } from '@backoffice/constants/refundConstants';
import { computeCompensationDetails } from '@backoffice/compensation/compensationDetails';
import { CustomDamageType } from '@shared/types/api/customDamage';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import DamagesCompensationForcedRows from '@backoffice/components/claim/compensation/tables/DamagesCompensationForcedRows.vue';
const claim = namespace('claim');
let DamageTableRow = class DamageTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
    }
    toggle(event) {
        this.active = !this.active;
        event.stopImmediatePropagation();
        return event === null || event === void 0 ? void 0 : event.preventDefault();
    }
    get counting() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting) !== null && _b !== void 0 ? _b : null;
    }
    get initialCustomDamages() {
        var _a, _b;
        return (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    get customDamages() {
        // si on a déjà modifié les customDamages mais qu'on les a tous supprimés
        if (this.draftPayload !== null && this.draftCustomDamages.length === 0) {
            return this.draftCustomDamages;
        }
        return this.draftCustomDamages.length > 0 ? this.draftCustomDamages : this.initialCustomDamages;
    }
    get computedRows() {
        var _a, _b, _c, _d, _e;
        if (this.row.type === 'default' &&
            ((_a = this.row.data) === null || _a === void 0 ? void 0 : _a.damageType) === ApiEntityType.CustomDamage &&
            this.isEditingClaim) {
            return [];
        }
        if (this.isEditingClaim) {
            return ((_c = (_b = this.row.data) === null || _b === void 0 ? void 0 : _b.edit) === null || _c === void 0 ? void 0 : _c.rows) ? (_e = (_d = this.row.data) === null || _d === void 0 ? void 0 : _d.edit) === null || _e === void 0 ? void 0 : _e.rows : [this.row];
        }
        return [this.row];
    }
    get draftCustomDamages() {
        var _a, _b;
        const counting = (_a = this.draftPayload) === null || _a === void 0 ? void 0 : _a.counting;
        return (_b = counting === null || counting === void 0 ? void 0 : counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    get tableType() {
        if (this.sectionType === ClaimCompensationSectionType.REALESTATEDAMAGES) {
            return CustomDamageType.realEstate;
        }
        else if (this.sectionType === ClaimCompensationSectionType.PERSONALPROPERTIESDAMAGES) {
            return CustomDamageType.personalProperties;
        }
        return null;
    }
    get compensationDetails() {
        if (!this.info)
            return null;
        return computeCompensationDetails(this.info, this.$tc.bind(this));
    }
    // ------------------------ DATAS FORCING
    get forcedCompensation() {
        var _a;
        return (_a = this.forcedCompensationMode) !== null && _a !== void 0 ? _a : null;
    }
    set forcedCompensation(compensation) {
        var _a;
        this.$emit('update:forced-compensation-mode', {
            mode: compensation,
            type: (_a = this.row.data) === null || _a === void 0 ? void 0 : _a.damageType,
        });
    }
    get forcedAmountComment() {
        var _a, _b;
        return (_b = (_a = this.counting) === null || _a === void 0 ? void 0 : _a.forcedAmountComment) !== null && _b !== void 0 ? _b : null;
    }
    set forcedAmountComment(forcedAmountComment) {
        if (!this.initialClaim)
            return;
        this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                forcedAmountComment,
            },
        });
    }
    get isDetailsRow() {
        var _a;
        return (_a = this.row.cells[0]) === null || _a === void 0 ? void 0 : _a.name.endsWith('.details');
    }
    // ------------------------ CELLS CHARACTERISTICS
    isExclusionCell(cell) {
        return (this.rowHasExclusionReason &&
            this.cellType(cell.data.columnName) === ClaimCompensationColumnName.CompensationType);
    }
    isRefundAppliedAmountsCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedTotal;
    }
    compensationModeDesciption(cell) {
        var _a;
        return ((_a = cell === null || cell === void 0 ? void 0 : cell.data) === null || _a === void 0 ? void 0 : _a.compensationModeDescription) || null;
    }
    isForcedAmountsCommentCell(cell) {
        return cell.name === 'totals-section.compensation.forced.comment.value' || null;
    }
    isForcedAmountsRow() {
        return this.row.cells.some((cell) => cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedImmediate ||
            cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedDelayed ||
            cell.name === ClaimCompensationCellName.TotalsSectionDeductibleForcedImmediate);
    }
    isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell) {
        return cell.name === 'totals-section.compensation.labor.and.material.amounts.comment.value';
    }
    displayValue(cell) {
        if (cell.type === 'amount' && cell.data.columnName === 'damage.ceiling') {
            return cell.displayValue;
        }
        return cell.displayValue.replace(/\n\n/g, '<br />');
    }
    async handleForcedAmount(event) {
        const mapping = {
            [ClaimCompensationCellName.TotalsSectionCompensationForcedImmediate]: 'forcedImmediateAmount',
            [ClaimCompensationCellName.TotalsSectionCompensationForcedDelayed]: 'forcedDelayedAmount',
            [ClaimCompensationCellName.TotalsSectionDeductibleForcedImmediate]: 'forcedDeductibleAmount',
        };
        const amountKey = mapping[event.cellName];
        if (amountKey && event.amount !== null) {
            const amount = Number(event.amount);
            if (!isNaN(amount)) {
                await this.forceAmount(amountKey, Number(amount.toFixed(2)) * 100);
            }
        }
    }
    // ------------------------ ROW CHARACTERISTICS
    get rowHasExclusionReason() {
        var _a;
        for (const cell of this.row.cells) {
            if ((_a = cell.data) === null || _a === void 0 ? void 0 : _a.exclusionReason) {
                return true;
            }
        }
        return false;
    }
    get isAllowanceSubRow() {
        return this.row.type === 'applied-allowance-container';
    }
    get isForcedAmounts() {
        var _a, _b, _c, _d;
        return this.row.type === 'footer' && ((_d = !!((_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.edit) === null || _c === void 0 ? void 0 : _c.rows)) !== null && _d !== void 0 ? _d : false);
    }
    get allowanceData() {
        return this.isAllowanceSubRow ? this.row.cells[0].data : null;
    }
    get compensationChoices() {
        return COMPENSATION_MODE_CHOICES.map((choice) => ({
            value: choice.value,
            label: this.$tc(choice.tradKey),
            shortLabel: this.$tc(choice.shortTradKey),
        }));
    }
    isShowDetailsCell(cell) {
        var _a;
        return !!((_a = cell.data) === null || _a === void 0 ? void 0 : _a.showDetails);
    }
    get isShowDetailsRow() {
        var _a;
        let isShow = false;
        for (const cell of this.row.cells) {
            if (((_a = cell.data) === null || _a === void 0 ? void 0 : _a.showDetails) === true) {
                isShow = true;
            }
        }
        return isShow;
    }
    get toggleIcon() {
        return this.active ? 'chevron-down' : 'chevron-right';
    }
    cellType(columnName = 'default') {
        const shortName = columnName.match(/[^.]+\.(.+)/);
        return shortName ? shortName[1] : columnName;
    }
    getCellClasses(cell, rowType) {
        const { columnName } = cell.data;
        const cssClasses = [];
        const cssType = 'damage-table-column__' + columnName.split('.').pop();
        cssClasses.push(cssType);
        if (cell.data.isRelatedToMinimumCharges) {
            cssClasses.push('compensation-table-cell__isRelatedToMinimumCharges');
        }
        if (this.isShowDetailsCell(cell)) {
            cssClasses.push('is-clickable');
        }
        if (this.rowHasExclusionReason) {
            cssClasses.push('has-text-danger');
        }
        if (this.cellType(columnName) === 'exclusion-reason') {
            cssClasses.push('has-background-white-bis has-shadow-inset has-text-grey-dark pl-5');
        }
        if (rowType === 'intermediate-result' ||
            rowType === 'pre-footer' ||
            rowType === 'footer' ||
            rowType === 'applied-allowance-header' ||
            this.isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell)) {
            cssClasses.push('has-text-weight-bold');
        }
        else {
            cssClasses.push('has-text-weight-normal');
        }
        if (cell.type === 'amount') {
            cssClasses.push('has-text-right');
        }
        if (this.isRefundAppliedAmountsCell(cell)) {
            cssClasses.push('compensation-table__cell compensation-table__cell--right compensation-table__cell--bold compensation-table__cell--bg-blue--strong');
        }
        if (this.isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell)) {
            cssClasses.push('has-text-danger');
        }
        if (this.isForcedAmountsCommentCell(cell) && this.isEditingClaim) {
            cssClasses.push('damage-table-column__comment--active');
        }
        return cssClasses.join(' ');
    }
    getRowClasses(row) {
        var _a, _b;
        const cssClasses = [];
        cssClasses.push('damage-row__' + row.type);
        if (row.type === 'intermediate-result') {
            cssClasses.push('has-text-weight-bold has-background-light');
        }
        if (row.type === 'applied-minimum-charge') {
            cssClasses.push('damage-row__default');
        }
        if (this.isAllowanceSubRow) {
            cssClasses.push('has-background-white-bis has-shadow-inset');
        }
        if (this.rowHasExclusionReason) {
            cssClasses.push('has-text-danger');
        }
        if (this.isShowDetailsRow) {
            cssClasses.push('damage-row__has-details');
        }
        if (this.active && this.isShowDetailsRow) {
            cssClasses.push('active');
        }
        if (row.type === 'metadata' && !this.isEditingClaim) {
            cssClasses.push('damage-row__add-damage--hidden');
        }
        if (this.isDetailsRow) {
            cssClasses.push('has-background-white-bis has-shadow-inset');
        }
        if (this.isForcedAmounts) {
            if ((_b = (_a = row.data) === null || _a === void 0 ? void 0 : _a.edit) === null || _b === void 0 ? void 0 : _b.rows) {
                cssClasses.push('deductible-amount_row');
            }
            else {
                cssClasses.push('deductible-amount_sub-row');
            }
        }
        return cssClasses;
    }
    async forceAmount(key, amount) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            return;
        }
        await this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                [key]: amount,
            },
        });
    }
};
__decorate([
    claim.State('initialClaim')
], DamageTableRow.prototype, "initialClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], DamageTableRow.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.State('draftPayload')
], DamageTableRow.prototype, "draftPayload", void 0);
__decorate([
    claim.State('info')
], DamageTableRow.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], DamageTableRow.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], DamageTableRow.prototype, "updateDraftPayload", void 0);
DamageTableRow = __decorate([
    Component({
        components: {
            ForcableAmountValue,
            AllowanceSubRow,
            DamagesCompensationForcedRows,
        },
        props: {
            row: {
                type: Object,
                required: true,
            },
            sectionType: {
                type: String,
                required: true,
                default: '',
            },
        },
        filters: {
            formatAmount,
        },
    })
], DamageTableRow);
export default DamageTableRow;
