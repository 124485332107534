import { OpenIDConnectScheme, } from '@nuxtjs/auth-next';
import { CONFIG } from '@shared/environment/environment';
export default class OpenIDConnectClaimsScheme extends OpenIDConnectScheme {
    constructor($auth, options, ...defaults) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { CONFIG } = require('@shared/environment/environment');
        const { apiUrl, keycloakUrl, keycloakClientId, keycloakConfigPath, keycloakLogoutPath } = CONFIG;
        const oidcTokenPath = `/oauth/jwt/get`;
        const oidcUserPath = `/auth/me`;
        super($auth, {
            name: options.name,
            responseType: 'code',
            grantType: 'authorization_code',
            clientId: keycloakClientId,
            token: {
                property: 'access',
                name: 'API-Authorization',
            },
            idToken: {
                property: 'id_token',
            },
            user: {
                property: false,
            },
            endpoints: {
                token: new URL(oidcTokenPath, apiUrl).href,
                userInfo: new URL(oidcUserPath, apiUrl).href,
                configuration: new URL(keycloakConfigPath, keycloakUrl).href,
                logout: new URL(keycloakLogoutPath, keycloakUrl).href,
            },
        }, ...defaults);
    }
    async fetchUser() {
        if (!this.check(false).valid) {
            return;
        }
        const { data } = await this.$auth.requestWith(this.name, {
            url: this.options.endpoints.userInfo,
        });
        this.$auth.setUser(data);
        await this.$auth.ctx.store.dispatch('settings/fetchMenuEntries', undefined, { root: true });
    }
    logout() {
        const { backofficeUrl } = CONFIG;
        if (this.options.endpoints.logout) {
            const opts = {
                client_id: this.options.clientId,
                post_logout_redirect_uri: new URL('/login?resetAuth=1', backofficeUrl).href,
            };
            const url = this.options.endpoints.logout + '?' + this.encodeQuery(opts);
            window.location.replace(url);
        }
    }
    encodeQuery(queryObject) {
        return Object.entries(queryObject)
            .filter(([_key, value]) => typeof value !== 'undefined')
            .map(([key, value]) => encodeURIComponent(key) + (value != null ? '=' + encodeURIComponent(String(value)) : ''))
            .join('&');
    }
}
