import { DEFAULT_PAGES_SECURITY, validateRole } from '@backoffice/types/security';
const middleware = async ({ $auth, $config, app, route, params, query, localePath, redirectLocale, getRouteBaseName, error, }) => {
    var _a, _b, _c, _d, _e;
    const routeName = getRouteBaseName(route);
    if (!routeName)
        return;
    const security = [
        (_d = (_c = (_b = (_a = $config.insurerConfig.backoffice) === null || _a === void 0 ? void 0 : _a.security) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c[routeName]) !== null && _d !== void 0 ? _d : DEFAULT_PAGES_SECURITY[routeName],
    ].flat();
    if (!security.length)
        return;
    if (route.query.resetAuth) {
        $auth.reset();
    }
    if (!$auth.loggedIn)
        return redirectLocale({ name: 'login' });
    await $auth.fetchUserOnce();
    const roles = $auth.user.roles;
    if (!security.some((role) => validateRole(roles, role))) {
        let path;
        if (route.redirectedFrom)
            path = route.redirectedFrom;
        else {
            const parent = route.matched[route.matched.length - 1].parent;
            const parentRoute = parent && ((_e = app.router) === null || _e === void 0 ? void 0 : _e.resolve(parent));
            if (parentRoute) {
                path = localePath({
                    name: getRouteBaseName(parentRoute.route),
                    params,
                    query,
                });
            }
            else
                path = localePath({ name: 'index' });
        }
        error({
            message: "Vous n'avez pas accès à cette page",
            statusCode: 403,
            path,
        });
    }
};
export default middleware;
