var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ForcableAmountValue from 'backoffice/components/claim/compensation/ForcableAmountValue.vue';
const claim = namespace('claim');
let CustomDamageRowInputs = class CustomDamageRowInputs extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
        this.damageLabel = null;
        this.damageAmount = null;
        this.timeout = null;
    }
    get counting() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting) !== null && _b !== void 0 ? _b : null;
    }
    created() {
        var _a;
        const initialDamage = { ...this.damage };
        this.damageLabel = (_a = initialDamage.description) !== null && _a !== void 0 ? _a : null;
        this.damageAmount = initialDamage.amount ? Number(this.damage.amount) / 100 : null;
    }
    damageUpdated() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.$emit('damage-updated', {
                description: this.damageLabel,
                amount: Number(this.damageAmount),
            });
            clearTimeout(this.timeout);
            this.timeout = null;
        }, 1000);
    }
    get customDamageLabel() {
        var _a;
        return (_a = this.damageLabel) !== null && _a !== void 0 ? _a : null;
    }
    set customDamageLabel(inputData) {
        this.damageLabel = inputData;
    }
    get customDamageAmount() {
        var _a;
        return (_a = this.damageAmount) !== null && _a !== void 0 ? _a : null;
    }
    set customDamageAmount(inputData) {
        this.damageAmount = inputData !== null && inputData !== void 0 ? inputData : null;
    }
};
__decorate([
    claim.State('initialClaim')
], CustomDamageRowInputs.prototype, "initialClaim", void 0);
__decorate([
    claim.State('info')
], CustomDamageRowInputs.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], CustomDamageRowInputs.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], CustomDamageRowInputs.prototype, "updateDraftPayload", void 0);
CustomDamageRowInputs = __decorate([
    Component({
        components: { ForcableAmountValue },
        props: {
            damage: {
                type: Object,
                required: true,
                default: '',
            },
            tableType: {
                type: String,
                required: true,
                default: null,
            },
        },
    })
], CustomDamageRowInputs);
export default CustomDamageRowInputs;
