var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DamagesCompensationTableHeader from '@backoffice/components/claim/compensation/tables/DamagesCompensationTableHeader.vue';
import DamageTableRow from '@backoffice/components/claim/compensation/tables/DamageTableRow.vue';
import { ClaimCompensationSectionType, } from '@backoffice/types/store';
import { DamageXmlLabel } from '@shared/types/api/contract';
import { CompensationMode } from '@shared/types/api/claim';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import CustomDamageRowInputs from '@backoffice/components/claim/compensation/tables/CustomDamageRowInputs.vue';
import { CustomDamageType } from '@shared/types/api/customDamage';
const claim = namespace('claim');
let DamagesCompensationTable = class DamagesCompensationTable extends Vue {
    get draftCustomDamages() {
        var _a, _b;
        const counting = (_a = this.draftPayload) === null || _a === void 0 ? void 0 : _a.counting;
        return (_b = counting === null || counting === void 0 ? void 0 : counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    get initialCustomDamages() {
        var _a, _b;
        return (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    get customDamages() {
        // si on a déjà modifié les customDamages mais qu'on les a tous supprimés
        if (this.draftPayload !== null && this.draftCustomDamages.length === 0) {
            return this.draftCustomDamages;
        }
        const damages = this.draftCustomDamages.length > 0 ? this.draftCustomDamages : this.initialCustomDamages;
        return damages.filter((damage) => {
            return damage.type === this.tableType;
        });
    }
    hasEditingRows(row) {
        var _a, _b;
        return !!((_b = (_a = row.data) === null || _a === void 0 ? void 0 : _a.edit) === null || _b === void 0 ? void 0 : _b.rows);
    }
    get tableType() {
        if (this.sectionType === ClaimCompensationSectionType.REALESTATEDAMAGES) {
            return CustomDamageType.realEstate;
        }
        else if (this.sectionType === ClaimCompensationSectionType.PERSONALPROPERTIESDAMAGES) {
            return CustomDamageType.personalProperties;
        }
        return null;
    }
    isCustomDamagesContainer(row) {
        var _a;
        let metadataRows = (_a = this.tableBodyRows) !== null && _a !== void 0 ? _a : [];
        metadataRows = metadataRows.filter((r) => r.type === 'metadata');
        return metadataRows.length > 0 && metadataRows[0] === row;
    }
    uniqueKey() {
        return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }
    addDamageLine() {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        else {
            const damagesToDraft = this.draftCustomDamages.length > 0
                ? structuredClone(this.draftCustomDamages)
                : structuredClone(this.initialCustomDamages);
            damagesToDraft.push({
                type: this.tableType,
                description: '',
                amount: 0,
                '@id': this.uniqueKey(),
            });
            this.updateDraftPayload({
                id: this.initialClaim.id,
                counting: {
                    id: this.initialClaim.counting.id,
                    customDamages: damagesToDraft,
                },
            });
        }
    }
    removeDamageLine(id) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        else {
            let damagesToDraft;
            if (this.draftCustomDamages.length === 0) {
                const damages = [...this.initialCustomDamages];
                const index = damages.findIndex((damage) => damage.id === id);
                if (index !== -1) {
                    damages.splice(index, 1);
                }
                damagesToDraft = damages;
            }
            else {
                const damages = [...this.draftCustomDamages];
                const index = damages.findIndex((damage) => {
                    var _a;
                    const damageId = (_a = damage.id) !== null && _a !== void 0 ? _a : damage['@id'];
                    return damageId === id;
                });
                if (index !== -1) {
                    damages.splice(index, 1);
                }
                damagesToDraft = [...damages];
            }
            this.updateDraftPayload({
                id: this.initialClaim.id,
                counting: {
                    id: this.initialClaim.counting.id,
                    customDamages: damagesToDraft,
                },
            });
        }
    }
    async handleCustomDamages(id, userInputDamage) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            return;
        }
        let damagesToDraft;
        const { description, amount } = userInputDamage;
        if (this.draftCustomDamages.length > 0) {
            damagesToDraft = structuredClone(this.draftCustomDamages);
        }
        else {
            damagesToDraft = structuredClone(this.initialCustomDamages);
        }
        const index = damagesToDraft.findIndex((damage) => {
            var _a;
            const damageId = (_a = damage.id) !== null && _a !== void 0 ? _a : damage['@id'];
            return damageId === id;
        });
        if (index !== -1) {
            damagesToDraft[index].description = description;
            damagesToDraft[index].amount = Number(amount.toFixed(2)) * 100;
        }
        await this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                customDamages: damagesToDraft,
            },
        });
    }
    get counting() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting) !== null && _b !== void 0 ? _b : null;
    }
    get isDeductibleForced() {
        var _a, _b;
        return (((_a = this.counting) === null || _a === void 0 ? void 0 : _a.forcedDeductibleAmount) !== null ||
            ((_b = this.counting) === null || _b === void 0 ? void 0 : _b.damagesGroup.refund.parameters.deductibleAmount.forcedValue) !== null);
    }
    get isImmediateForced() {
        var _a, _b;
        return (((_a = this.counting) === null || _a === void 0 ? void 0 : _a.forcedImmediateAmount) !== null ||
            ((_b = this.counting) === null || _b === void 0 ? void 0 : _b.damagesGroup.refund.compensation.immediate.forcedValue) !== null);
    }
    get isDelayedForced() {
        var _a, _b;
        return (((_a = this.counting) === null || _a === void 0 ? void 0 : _a.forcedDelayedAmount) !== null ||
            ((_b = this.counting) === null || _b === void 0 ? void 0 : _b.damagesGroup.refund.compensation.delayed.forcedValue) !== null);
    }
    async resetForcedAmounts() {
        if (this.initialClaim) {
            await this.updateDraftPayload({
                id: this.initialClaim.id,
                counting: {
                    id: this.initialClaim.counting.id,
                    forcedDeductibleAmount: null,
                    forcedImmediateAmount: null,
                    forcedDelayedAmount: null,
                },
            });
        }
    }
    // --------------------- Table rows data
    get damagesRows() {
        var _a;
        return (_a = this.section.modes.default.rows) !== null && _a !== void 0 ? _a : [];
    }
    get headerRow() {
        var _a;
        if (this.damagesRows && this.damagesRows.length) {
            return (_a = this.damagesRows) === null || _a === void 0 ? void 0 : _a.find((row) => row.type === 'header');
        }
        return undefined;
    }
    get tableBodyRows() {
        var _a;
        if (this.damagesRows && this.damagesRows.length) {
            const bodyRows = (_a = this.damagesRows) === null || _a === void 0 ? void 0 : _a.filter((row) => row.type !== 'header' && row.type !== 'footer' && row.type !== 'post-footer').map((row) => {
                return row;
            });
            return bodyRows;
        }
        return null;
    }
    get tableFootRows() {
        var _a;
        if (this.damagesRows && this.damagesRows.length) {
            const footRows = (_a = this.damagesRows) === null || _a === void 0 ? void 0 : _a.filter((row) => row.type === 'footer' || row.type === 'post-footer');
            return footRows;
        }
        return null;
    }
    get tableClasses() {
        if (this.sectionType === ClaimCompensationSectionType.REALESTATEDAMAGES) {
            return 'real-estate__table table is-fullwidth is-vertical-centered slim-table';
        }
        else if (this.sectionType === ClaimCompensationSectionType.PERSONALPROPERTIESDAMAGES) {
            return 'personal-properties__table table is-fullwidth is-vertical-centered';
        }
        return 'total__table table is-fullwidth is-vertical-centered';
    }
    get damagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.damagesGroup.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.RealEstate)) !== null && _b !== void 0 ? _b : null);
    }
    get breakInDamagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.BreakInDamage)) !== null && _b !== void 0 ? _b : null);
    }
    get roomDamagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.RoomDamage)) !== null && _b !== void 0 ? _b : null);
    }
    get externalDamagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.ExternalDamage)) !== null && _b !== void 0 ? _b : null);
    }
    get glassDamagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.GlassDamage)) !== null && _b !== void 0 ? _b : null);
    }
    get electricalDamagesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.ElectricalDamage)) !== null && _b !== void 0 ? _b : null);
    }
    get appliedMimimumChargesGroup() {
        var _a, _b;
        return ((_b = (_a = this.damagesGroup) === null || _a === void 0 ? void 0 : _a.childrenDamagesGroups.find((d) => d.xmlLabel === DamageXmlLabel.AppliedMinimumCharge)) !== null && _b !== void 0 ? _b : null);
    }
    get breakInDamages() {
        var _a, _b;
        return ((_b = (_a = this.breakInDamagesGroup) === null || _a === void 0 ? void 0 : _a.breakInDamages) !== null && _b !== void 0 ? _b : [])
            .slice()
            .sort((a, b) => a.id.localeCompare(b.id))
            .sort((a, b) => a.type.localeCompare(b.type));
    }
    get roomDamages() {
        var _a, _b;
        return ((_b = (_a = this.roomDamagesGroup) === null || _a === void 0 ? void 0 : _a.roomDamages) !== null && _b !== void 0 ? _b : [])
            .slice()
            .sort((a, b) => a.id.localeCompare(b.id))
            .sort((a, b) => a.roomNameSlug.localeCompare(b.roomNameSlug));
    }
    get externalDamages() {
        var _a, _b;
        return ((_b = (_a = this.externalDamagesGroup) === null || _a === void 0 ? void 0 : _a.externalDamages) !== null && _b !== void 0 ? _b : [])
            .slice()
            .sort((a, b) => a.id.localeCompare(b.id))
            .sort((a, b) => a.slug.localeCompare(b.slug));
    }
    get glassDamages() {
        var _a, _b;
        return ((_b = (_a = this.glassDamagesGroup) === null || _a === void 0 ? void 0 : _a.glassDamages) !== null && _b !== void 0 ? _b : [])
            .slice()
            .sort((a, b) => a.id.localeCompare(b.id))
            .sort((a, b) => a.type.localeCompare(b.type));
    }
    get electricalDamages() {
        var _a, _b;
        return ((_b = (_a = this.electricalDamagesGroup) === null || _a === void 0 ? void 0 : _a.electricalDamages) !== null && _b !== void 0 ? _b : [])
            .slice()
            .sort((a, b) => a.id.localeCompare(b.id))
            .sort((a, b) => a.type.localeCompare(b.type));
    }
    async onForceCompensationMode(damageIri, event) {
        var _a;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        if (!claim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            return;
        }
        const payload = {
            id: claim.id,
            counting: { id: claim.counting.id },
        };
        const compensationModeHasBeenForced = event.mode !== null && event.mode !== CompensationMode.CompensationModeNone;
        switch (event.type) {
            case ApiEntityType.BreakInDamage: {
                payload.counting.breakInDamages = this.breakInDamages.map((breakInDamage) => {
                    if (damageIri === breakInDamage.id) {
                        return {
                            ...breakInDamage,
                            pendingCompensationMode: event.mode,
                            compensationModeHasBeenForced,
                        };
                    }
                    return breakInDamage;
                });
                break;
            }
            case ApiEntityType.RoomDamage: {
                payload.counting.roomDamages = this.roomDamages.map((roomDamage) => {
                    if (damageIri === roomDamage.id) {
                        return {
                            ...roomDamage,
                            compensationMode: event.mode,
                            compensationModeHasBeenForced,
                        };
                    }
                    return roomDamage;
                });
                break;
            }
            case ApiEntityType.ExternalDamage: {
                payload.counting.externalDamages = this.externalDamages.map((externalDamage) => {
                    if (damageIri === externalDamage.id) {
                        return {
                            ...externalDamage,
                            compensationMode: event.mode,
                            compensationModeHasBeenForced,
                        };
                    }
                    return externalDamage;
                });
                break;
            }
            case ApiEntityType.GlassDamage: {
                payload.counting.glassDamages = this.glassDamages.map((glassDamage) => {
                    if (damageIri === glassDamage.id) {
                        return {
                            ...glassDamage,
                            compensationMode: event.mode,
                            compensationModeHasBeenForced,
                        };
                    }
                    return glassDamage;
                });
                break;
            }
            case ApiEntityType.ElectricalDamage: {
                payload.counting.electricalDamages = this.electricalDamages.map((electricalDamage) => {
                    if (damageIri === electricalDamage.id) {
                        return {
                            ...electricalDamage,
                            compensationMode: event.mode,
                            compensationModeHasBeenForced,
                        };
                    }
                    return electricalDamage;
                });
                break;
            }
            default: {
                this.$buefy.toast.open({
                    message: 'Une erreur est survenue, merci de réessayer.',
                    type: 'is-danger',
                    position: 'is-bottom',
                });
                return;
            }
        }
        await this.updateDraftPayload(payload);
    }
};
__decorate([
    claim.State('info')
], DamagesCompensationTable.prototype, "info", void 0);
__decorate([
    claim.State('initialClaim')
], DamagesCompensationTable.prototype, "initialClaim", void 0);
__decorate([
    claim.State('draftPayload')
], DamagesCompensationTable.prototype, "draftPayload", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], DamagesCompensationTable.prototype, "updateDraftPayload", void 0);
__decorate([
    claim.State('isEditingClaim')
], DamagesCompensationTable.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], DamagesCompensationTable.prototype, "isClaimUpdating", void 0);
DamagesCompensationTable = __decorate([
    Component({
        components: {
            DamageTableRow,
            DamagesCompensationTableHeader,
            CustomDamageRowInputs,
        },
        props: {
            section: {
                type: Object,
                default: null,
            },
            sectionType: {
                type: String,
                required: true,
                default: '',
            },
        },
    })
], DamagesCompensationTable);
export default DamagesCompensationTable;
