var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let AllowanceSubRow = class AllowanceSubRow extends Vue {
    computedCells(cells, rowType) {
        return cells
            .map((cell, index) => {
            const computedCell = { ...cell };
            if (cell.data.cellSkipable) {
                return null;
            }
            if (index === 0) {
                computedCell.scope = 'row';
            }
            if (this.getCellClasses(cell, rowType)) {
                computedCell.class = this.getCellClasses(cell, rowType);
            }
            return computedCell;
        })
            .filter((cell) => cell !== null);
    }
    getCellClasses(cell, rowType) {
        const { columnName } = cell.data;
        const cssClasses = [];
        const cssType = 'damage-table-column__' + columnName.split('.').pop();
        cssClasses.push(cssType);
        if (cell.data.isRelatedToMinimumCharges) {
            cssClasses.push('compensation-table-cell__isRelatedToMinimumCharges');
        }
        if (rowType === 'intermediate-result' ||
            rowType === 'pre-footer' ||
            rowType === 'footer' ||
            rowType === 'applied-allowance-header') {
            cssClasses.push('has-text-weight-bold');
        }
        else {
            cssClasses.push('has-text-weight-normal');
        }
        if (rowType === 'footer' && cell.type === 'amount') {
            cssClasses.push('has-text-right');
        }
        return cssClasses.join(' ');
    }
    cellType(columnName = 'default') {
        const shortName = columnName.match(/[^.]+\.(.+)/);
        return shortName ? shortName[1] : columnName;
    }
};
AllowanceSubRow = __decorate([
    Component({
        props: {
            row: {
                type: Object,
                required: true,
            },
            allowanceData: {
                type: Object,
                required: true,
            },
        },
    })
], AllowanceSubRow);
export default AllowanceSubRow;
